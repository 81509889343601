import styled from 'styled-components';
import { Banner } from '../../components/Banner';

export const Content = styled.div`
  margin-left: ${(props) => (props.theme.host.isDesktopApp ? '260px' : '0')};
`;

export const Container = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.screens.dsm}) {
    ${(props) => props.theme.host.isDesktopApp && 'flex-direction: column;'};
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    flex-direction: column;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: -52px 33px 0;

  > * {
    margin-bottom: 18px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.screens.dxxl}) {
    margin: 30px auto 0;
  }

  @media (max-width: ${(props) => props.theme.screens.dsm}) {
    ${(props) =>
      props.theme.host.isDesktopApp
        ? '> * { ' +
          'display: none; ' +
          'margin-bottom: 0; ' +
          '} ' +
          '> &:first-child { ' +
          'display: block; ' +
          '}' +
          '}'
        : ''};
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    display: none;

    > * {
      display: none;
      margin-bottom: 0;
    }

    > &:first-child {
      display: block;
    }
  }
`;

export const MobileCarouselContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${(props) => props.theme.screens.dsm}) {
    ${(props) => props.theme.host.isDesktopApp && 'display: block;'};
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    display: block;
  }
`;

export const HelpContainer = styled.div`
  max-width: 268px;
  margin-left: 30px;

  @media (max-width: ${(props) => props.theme.screens.dmd}) {
    ${(props) => props.theme.host.isDesktopApp && 'display: block;'};
  }

  @media (max-width: ${(props) => props.theme.screens.md}) {
    display: block;
  }

  @media (max-width: ${(props) => props.theme.screens.dsm}) {
    ${(props) => props.theme.host.isDesktopApp && 'display: none;'};
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    display: none;
  }
`;

export const HelpContainerMobile = styled.div`
  display: none;
  max-width: 268px;
  margin-left: 26px;

  @media (max-width: ${(props) => props.theme.screens.dsm}) {
    ${(props) => props.theme.host.isDesktopApp && 'display: block;'};
    margin-top: 20px;
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    display: block;
    margin-top: 0px;
  }
`;

export const StyledBanner = styled(Banner)`
  margin-bottom: -52px;
`;

export const ContentWrapper = styled.div`
  margin: 16px 16px 0;
`;
