import { useEffect, useState } from 'react';
import { getFeatureFlag } from '../utils/featureFlag';

const defaultFeatureFlags = {
  smartAdvAppStores: false,
  smartAdvShortcuts: false,
  releaseVersionHpSmartAdvance: 'published',
  smartAdvIDCardScanning: false,
  smartAdvMobileProductivity: false
};

export const useFeatureFlags = (key) => {
  const defaultValue = defaultFeatureFlags[key];
  const [flagValue, setFlagValue] = useState(defaultValue || false);

  useEffect(() => {
    const getFlagValue = async () => {
      const featureFlagValue = await getFeatureFlag({ key, defaultValue });
      setFlagValue(featureFlagValue);
    };

    getFlagValue();
  }, [defaultValue, key]);

  return flagValue;
};
