import styled from 'styled-components';

export const StyledMobileCarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  min-height: 300px;
  width: 288px;
`;

export const Image = styled.div`
  align-items: center;
  background-color: #d2ecff;

  display: flex;
  height: 151px;
  justify-content: center;
  width: 288px;

  img {
    height: 80px;
  }
`;

export const AnimationContainer = styled.div`
  border-radius: 16px 16px 0 0;
  height: 151px;
  overflow: hidden;
  width: 288px;
`;

export const Content = styled.div`
  background-color: white;
  border-radius: 0 0 16px 16px;
  flex-grow: 1;
  padding: 14px 18px 18px 18px;
  width: 252px;
  margin-bottom: 18px;
  box-sizing: content-box;
`;

export const Title = styled.div`
  color: #212121;
`;

export const Description = styled.div`
  color: #404040;
`;
