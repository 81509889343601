import React from 'react';
import { useI18n } from '@jarvis/react-portal-addons';
import { Container, HelpLink, Title } from './styles';
import { publishEvent, LearnMoreHelpHyperlink } from '../../utils/analytics';

const onClickAction = (evt) => {
  evt.preventDefault();

  publishEvent(LearnMoreHelpHyperlink);

  if (window.Portal) {
    window.Portal.deeplinks.navigateFunction({
      schema: 'hp-smart-app',
      key: 'help_hp_smart_advance'
    });
  } else if (window.Shell) {
    window.Shell.v1.navigation.push('/help/hp-smart-advance');
  }

  return false;
};

export const Help = () => {
  const { t } = useI18n();
  return (
    <Container data-testid="help-container">
      <Title>{t('help.subHeader')}</Title>
      <HelpLink
        onClick={onClickAction}
        className="caption"
        data-testid="help-link"
      >
        {t('help.link')}
      </HelpLink>
    </Container>
  );
};
