const bulgarian = require('./Microsoft_app_store_badge_Bulgarian.svg');
const czech = require('./Microsoft_app_store_badge_Czech.svg');
const danish = require('./Microsoft_app_store_badge_Danish.svg');
const german = require('./Microsoft_app_store_badge_German.svg');
const greek = require('./Microsoft_app_store_badge_Greek.svg');
const english = require('./Microsoft_app_store_badge_English.svg');
const spanish = require('./Microsoft_app_store_badge_Spanish.svg');
const estonian = require('./Microsoft_app_store_badge_Estonian.svg');
const finish = require('./Microsoft_app_store_badge_Finnish.svg');
const french = require('./Microsoft_app_store_badge_French.svg');
const croatian = require('./Microsoft_app_store_badge_Croatian.svg');
const hungarian = require('./Microsoft_app_store_badge_Hungarian.svg');
const italian = require('./Microsoft_app_store_badge_Italian.svg');
const japanese = require('./Microsoft_app_store_badge_Japanese.svg');
const korean = require('./Microsoft_app_store_badge_Korean.svg');
const lithuanian = require('./Microsoft_app_store_badge_Lithuanian.svg');
const latvian = require('./Microsoft_app_store_badge_Latvian.svg');
const norwegian = require('./Microsoft_app_store_badge_Norwegian.svg');
const dutch = require('./Microsoft_app_store_badge_Dutch.svg');
const polish = require('./Microsoft_app_store_badge_Polish.svg');
const portugueseBrazilian = require('./Microsoft_app_store_badge_Portuguese-Brazilian.svg');
const portuguesePortugal = require('./Microsoft_app_store_badge_Portuguese-Portugal.svg');
const romanian = require('./Microsoft_app_store_badge_Romanian.svg');
const russian = require('./Microsoft_app_store_badge_Russian.svg');
const slovak = require('./Microsoft_app_store_badge_Slovak.svg');
const slovenian = require('./Microsoft_app_store_badge_Slovenian.svg');
const swedish = require('./Microsoft_app_store_badge_Swedish.svg');
const turkish = require('./Microsoft_app_store_badge_Turkish.svg');
const chineseSimplified = require('./Microsoft_app_store_badge_Chinese_Simplified.svg');
const chineseTraditional = require('./Microsoft_app_store_badge_Chinese-Traditional.svg');

const MicrosoftAppStoreImages = {
  bulgarian,
  czech,
  danish,
  german,
  greek,
  english,
  spanish,
  estonian,
  finish,
  french,
  croatian,
  hungarian,
  italian,
  japanese,
  korean,
  lithuanian,
  latvian,
  norwegian,
  dutch,
  polish,
  portugueseBrazilian,
  portuguesePortugal,
  romanian,
  russian,
  slovak,
  slovenian,
  swedish,
  turkish,
  chineseSimplified,
  chineseTraditional
};

export default MicrosoftAppStoreImages;
