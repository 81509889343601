import React from 'react';
import PropTypes from 'prop-types';
import { Content, Description, Image, StyledCardItem, Title } from './styles';

export const CardItem = (props) => {
  const { description, image, title } = props;

  return (
    <StyledCardItem data-testid="card-item-container">
      <Image>
        <img
          data-testid="card-item-image"
          src={image.url}
          alt={image.alt || 'icon'}
        />
      </Image>
      <Content>
        <Title
          data-testid="card-item-title"
          className="caption"
        >
          {title}
        </Title>
        <Description
          data-testid="card-item-description"
          className="caption"
        >
          {description}
        </Description>
      </Content>
    </StyledCardItem>
  );
};

CardItem.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired
};
