import React from 'react';
import PropTypes from 'prop-types';
import Animations from '../../animations';
import { Card } from '../Card';
import { CardItem } from '../CardItem';
import { CarouselItem } from '../CarouselItem';
import { useI18n } from '@jarvis/react-portal-addons';
import { Asterisk } from './styles';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import Images from '../../assets/images';
import { Carousel } from '../Carousel';

const PUBLISHED = 'published';

export const AdvancedScanCards = ({ releaseFeatureFlag }) => {
  const { t } = useI18n();
  const smartAdvIDCardScanning = useFeatureFlags('smartAdvIDCardScanning');

  return (
    <Card
      data-testid="advanced-scan-card"
      title={t(
        `advancedScanFeatureCard.asterisk.releases.${releaseFeatureFlag}.desktopHeader`
      )}
      content={
        <React.Fragment>
          <CardItem
            image={{ url: Images.iconMultiItemRecognition.toString() }}
            title={t('advancedScanFeatureCard.feature1.header')}
            description={t('advancedScanFeatureCard.feature1.description')}
          />
          <CardItem
            image={{ url: Images.iconFlattenPages.toString() }}
            title={t('advancedScanFeatureCard.feature5.header')}
            description={t('advancedScanFeatureCard.feature5.description')}
          />
          <CardItem
            image={{ url: Images.iconSaveAsTextFiles.toString() }}
            title={t('advancedScanFeatureCard.feature3.header')}
            description={t('advancedScanFeatureCard.feature3.description')}
          />
          <CardItem
            image={{ url: Images.iconAutoHeal.toString() }}
            title={t('advancedScanFeatureCard.feature6.header')}
            description={t('advancedScanFeatureCard.feature6.description')}
          />
          <CardItem
            image={{ url: Images.iconBookScanning.toString() }}
            title={t('advancedScanFeatureCard.feature4.header')}
            description={t('advancedScanFeatureCard.feature4.description')}
          />
          {smartAdvIDCardScanning && (
            <CardItem
              image={{ url: Images.iconIDCardScanning.toString() }}
              title={t('advancedScanFeatureCard.feature8.header')}
              description={t('advancedScanFeatureCard.feature8.description')}
            />
          )}
          {releaseFeatureFlag !== PUBLISHED && (
            <Asterisk data-testid="advanced-scan-card-item-asterisk">
              {t(
                `advancedScanFeatureCard.asterisk.releases.${releaseFeatureFlag}.desktopDescription`
              )}
            </Asterisk>
          )}
        </React.Fragment>
      }
    />
  );
};

AdvancedScanCards.defaultProps = {
  releaseFeatureFlag: 'published'
};

AdvancedScanCards.propTypes = {
  releaseFeatureFlag: PropTypes.string
};

export const AdvancedScanCarousel = ({ releaseFeatureFlag }) => {
  const { t } = useI18n();
  const smartAdvIDCardScanning = useFeatureFlags('smartAdvIDCardScanning');

  return (
    <React.Fragment>
      <Carousel
        sliderTopAndBottomMargin="10px"
        title={t(
          `advancedScanFeatureCard.asterisk.releases.${releaseFeatureFlag}.mobileHeader`
        )}
        description={
          releaseFeatureFlag !== PUBLISHED ? (
            <Asterisk
              data-testid="advanced-scan-card-item-asterisk"
              type="mobile"
            >
              {t(
                `advancedScanFeatureCard.asterisk.releases.${releaseFeatureFlag}.mobileDescription`
              )}
            </Asterisk>
          ) : null
        }
      >
        <CarouselItem
          animation={{
            animationData: Animations.multiItemRecognition,
            containerId:
              'advanced-scan-multi-recognition-carousel-item-container'
          }}
          title={t('advancedScanFeatureCard.feature1.header')}
          description={t('advancedScanFeatureCard.feature1.description')}
        />
        <CarouselItem
          animation={{
            animationData: Animations.saveAsTextFiles,
            containerId:
              'advanced-scan-save-as-text-files-carousel-item-container'
          }}
          title={t('advancedScanFeatureCard.feature5.header')}
          description={t('advancedScanFeatureCard.feature4.description')}
        />
        <CarouselItem
          animation={{
            animationData: Animations.flattenPages,
            containerId: 'advanced-scan-flatten-pages-carousel-item-container'
          }}
          title={t('advancedScanFeatureCard.feature3.header')}
          description={t('advancedScanFeatureCard.feature3.description')}
        />
        <CarouselItem
          animation={{
            animationData: Animations.autoHeal,
            containerId: 'advanced-scan-auto-heal-carousel-item-container'
          }}
          title={t('advancedScanFeatureCard.feature6.header')}
          description={t('advancedScanFeatureCard.feature6.description')}
        />
        <CarouselItem
          animation={{
            animationData: Animations.bookScanning,
            containerId: 'advanced-scan-book-scanning-carousel-item-container'
          }}
          title={t('advancedScanFeatureCard.feature4.header')}
          description={t('advancedScanFeatureCard.feature4.description')}
        />
        {smartAdvIDCardScanning && (
          <CarouselItem
            animation={{
              animationData: Animations.idCardScanning,
              containerId:
                'advanced-scan-id-cards-scanning-carousel-item-container'
            }}
            title={t('advancedScanFeatureCard.feature8.header')}
            description={t('advancedScanFeatureCard.feature8.description')}
          />
        )}
      </Carousel>
    </React.Fragment>
  );
};

AdvancedScanCarousel.defaultProps = {
  releaseFeatureFlag: PUBLISHED
};

AdvancedScanCarousel.propTypes = {
  releaseFeatureFlag: PropTypes.string
};
