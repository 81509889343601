import { getCookie } from './cookie';
import { STRATUS_ACCESS_TOKEN } from '../constants/cookieNames';

export const decodeToken = (token) => {
  if (!token) {
    return null;
  }

  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    return null;
  }
};

export const getTenantId = async (orgSelector, authProvider) => {
  if (orgSelector) {
    const orgTenantId = orgSelector.getOrgTenantId?.();
    if (orgTenantId) return orgTenantId;
  }

  if (authProvider) {
    const accessTokenFromAuthProvider = await authProvider.getAccessToken?.();
    const decodedTokenFromAuthProvider = decodeToken(
      accessTokenFromAuthProvider
    );

    const tenantIdFromAuthProviderToken =
      decodedTokenFromAuthProvider?.tenant_id;
    if (tenantIdFromAuthProviderToken) return tenantIdFromAuthProviderToken;
  }

  const accessTokenFromCookie = getCookie(STRATUS_ACCESS_TOKEN);
  const decodedTokenFromCookie = decodeToken(accessTokenFromCookie);
  const tenantIdFromCookie = decodedTokenFromCookie?.tenant_id;
  if (tenantIdFromCookie) return tenantIdFromCookie;

  return null;
};
