import { Button } from '@veneer/core';
import styled from 'styled-components';
import Images from '../../assets/images';

export const Background = styled.div`
  align-items: center;
  background-color: #009f86;
  background-image: url('${Images.bannerNotebooks}');
  background-position: 768px top;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: ${(props) => props.theme.screens.dxxl}) {
    background-position: right top;
  }

  @media (max-width: ${(props) => props.theme.screens.dmd}) {
    background-position: left 120% top;
  }

  @media (max-width: ${(props) => props.theme.screens.lg}) {
    background-position: left 130% top;
  }

  @media (max-width: ${(props) => props.theme.screens.md}) {
    background-position: right top;
    background-size: 217px 100%;
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    background-image: none;
    background-position: unset;
    background-repeat: unset;
    background-size: unset;
    margin-bottom: 25px;
  }

  @media (max-width: ${(props) => props.theme.screens.xsm}) {
    margin-bottom: 25px;
  }
`;

export const GetSmartAppButton = styled(Button)`
  && {
    background-color: white;
    border-color: white;
  }

  &&:hover {
    background-color: #f8f8f8;
    border-color: #f8f8f8;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 90px;
  margin-top: -90px;

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    text-align: center;
    padding-bottom: 32px;
  }

  @media (max-width: ${(props) => props.theme.screens.xsm}) {
    padding-top: 20px;
  }
`;

export const Container = styled.div`
  margin: 0 33px;
  margin-left: 50px;
  padding: 0 33px;

  a {
    text-decoration: none;
  }

  a span {
    color: #009f86;
  }

  @media (max-width: ${(props) => props.theme.screens.dxxl}) {
    margin: 0 auto;
    width: 592px;
  }

  @media (max-width: ${(props) => props.theme.screens.lg}) {
    width: 572px;
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    width: 400px;
  }

  @media (max-width: ${(props) => props.theme.screens.xsm}) {
    box-sizing: border-box;
    width: 100%;
  }
`;

export const EllipseContainer = styled.div`
  padding: 48px 0 20px;

  @media (max-width: ${(props) => props.theme.screens.md}) {
    padding: 32px 0 10px;
  }
`;

export const Ellipse = styled.div`
  border-radius: 19px;
  background-color: #fcbe2a;
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  width: 57px;

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  color: #ffffff;
  font-size: 48px;
  line-height: 64px;

  @media (max-width: ${(props) => props.theme.screens.md}) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    text-align: center;
  }
`;

export const Description = styled.div`
  color: #ffffff;
  font-size: 24px;
  line-height: 34px;
  width: 645px;
  padding-bottom: 90px;

  @media (max-width: ${(props) => props.theme.screens.xxl}) {
    width: 495px;
  }

  @media (max-width: ${(props) => props.theme.screens.xlg}) {
    width: 350px;
  }

  @media (max-width: ${(props) => props.theme.screens.md}) {
    font-size: 16px;
    width: 80%;
    line-height: 24px;
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
`;
