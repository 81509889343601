import { useCallback } from 'react';
import {
  AccountMgtSvcClient,
  UserMgtSvcClientv3
} from '@jarvis/web-stratus-client';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import { getFeatureFlag } from '../utils/featureFlag';
import {
  ENTITLEMENTS,
  ENTITLEMENT_STATES,
  ENTITLEMENT_TYPES,
  SMART_ADVANCE_SOLUTIONS
} from '../constants/entitlements';

const getSmartAdvanceEntitlement = (entitlementList) =>
  entitlementList.find(
    (entitlement) =>
      entitlement.serviceId === ENTITLEMENTS.SMART_ADVANCE &&
      entitlement.type === ENTITLEMENT_TYPES.BENEFIT &&
      [ENTITLEMENT_STATES.ENABLED, ENTITLEMENT_STATES.PENDING].includes(
        entitlement.state
      )
  );

export function useSubscriptionInfoApiCall({ authProvider, stack }) {
  const { grants } = useRootContext();

  const apiCall = useCallback(
    async ({ accountId }) => {
      let solutions = {};

      const useGrantsInsteadOfEntitlements = await getFeatureFlag({
        key: 'useGrantsInsteadOfEntitlements',
        defaultValue: false
      });

      if (useGrantsInsteadOfEntitlements) {
        for (const solution of SMART_ADVANCE_SOLUTIONS) {
          const hasGrants = await grants.checkGrants([
            { grant: solution.entitlement }
          ]);

          const solutionCanAppear = await getFeatureFlag({
            key: solution.featureFlag,
            defaultValue: solution.featureFlag ? false : true
          });

          solutions = {
            ...solutions,
            [`${solution.name}`]: hasGrants && solutionCanAppear
          };
        }
      } else {
        const userClient = new UserMgtSvcClientv3(stack, authProvider);

        const userClientResponse = await userClient.getCurrentActiveUser();

        const userId = userClientResponse?.data?.resourceId;

        const accountMgtSvcClient = new AccountMgtSvcClient(
          stack,
          authProvider
        );

        const response = await accountMgtSvcClient.getProgramInfos(
          accountId,
          userId
        );

        if (!response?.data) return {};

        const yetiEntitlementList = [
          ...(response.data?.programInfoMap?.YETI?.entitlementList || []),
          ...(response.data?.programInfoMap?.YETI?.subscriptionOfferingList ||
            [])
        ];

        const ucdeEntitlementList = [
          ...(response.data?.programInfoMap?.UCDE?.entitlementList || []),
          ...(response.data?.programInfoMap?.UCDE?.subscriptionOfferingList ||
            [])
        ];

        const entitlementList =
          yetiEntitlementList.length > 0
            ? yetiEntitlementList
            : ucdeEntitlementList;

        const smartAdvanceEntitlement =
          getSmartAdvanceEntitlement(entitlementList);

        const softwareProductInfoList = [
          ...(smartAdvanceEntitlement?.softwareProductInfoList || []),
          ...entitlementList.filter(
            (item) => item.state === ENTITLEMENT_STATES.ENABLED
          )
        ];

        for (const solution of SMART_ADVANCE_SOLUTIONS) {
          const selectedProduct = softwareProductInfoList.find(
            (product) => product.serviceId === solution.entitlement
          );

          const solutionCanAppear = await getFeatureFlag({
            key: solution.featureFlag,
            defaultValue: solution.featureFlag ? false : true
          });

          solutions = {
            ...solutions,
            [`${solution.name}`]: !!selectedProduct && solutionCanAppear
          };
        }
      }

      return { solutions };
    },
    [authProvider, grants, stack]
  );

  return useApiCall({
    apiCall,
    init: false
  });
}
