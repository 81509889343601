const bg = require('./Mac_app_store_badge_BG.svg');
const cz = require('./Mac_app_store_badge_CZ.svg');
const dk = require('./Mac_app_store_badge_DK.svg');
const de = require('./Mac_app_store_badge_DE.svg');
const gr = require('./Mac_app_store_badge_GR.svg');
const usUk = require('./Mac_app_store_badge_US-UK.svg');
const es = require('./Mac_app_store_badge_ES.svg');
const ee = require('./Mac_app_store_badge_EE.svg');
const fi = require('./Mac_app_store_badge_FI.svg');
const fr = require('./Mac_app_store_badge_FR.svg');
const frCa = require('./Mac_app_store_badge_FRCA.svg');
const hu = require('./Mac_app_store_badge_HU.svg');
const it = require('./Mac_app_store_badge_IT.svg');
const jp = require('./Mac_app_store_badge_JP.svg');
const kr = require('./Mac_app_store_badge_KR.svg');
const lt = require('./Mac_app_store_badge_LT.svg');
const lv = require('./Mac_app_store_badge_LV.svg');
const no = require('./Mac_app_store_badge_NO.svg');
const nl = require('./Mac_app_store_badge_NL.svg');
const pl = require('./Mac_app_store_badge_PL.svg');
const ptBr = require('./Mac_app_store_badge_PTBR.svg');
const ptPt = require('./Mac_app_store_badge_PTPT.svg');
const ro = require('./Mac_app_store_badge_RO.svg');
const ru = require('./Mac_app_store_badge_RU.svg');
const sk = require('./Mac_app_store_badge_SK.svg');
const si = require('./Mac_app_store_badge_SI.svg');
const se = require('./Mac_app_store_badge_SE.svg');
const tr = require('./Mac_app_store_badge_TR.svg');
const cnSc = require('./Mac_app_store_badge_CNSC.svg');
const cnTc = require('./Mac_app_store_badge_CNTC.svg');

const MacAppStoreImages = {
  bg,
  cz,
  dk,
  de,
  gr,
  usUk,
  es,
  ee,
  fi,
  fr,
  frCa,
  hu,
  it,
  jp,
  kr,
  lt,
  lv,
  no,
  nl,
  pl,
  ptBr,
  ptPt,
  ro,
  ru,
  sk,
  si,
  se,
  tr,
  cnSc,
  cnTc
};

export default MacAppStoreImages;
