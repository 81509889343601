const bg = require('./Google_app_store_badge_bg.svg');
const cs = require('./Google_app_store_badge_cs.svg');
const da = require('./Google_app_store_badge_da.svg');
const de = require('./Google_app_store_badge_de.svg');
const el = require('./Google_app_store_badge_el.svg');
const en = require('./Google_app_store_badge_en.svg');
const es = require('./Google_app_store_badge_es.svg');
const et = require('./Google_app_store_badge_et.svg');
const fi = require('./Google_app_store_badge_fi.svg');
const fr = require('./Google_app_store_badge_fr.svg');
const frCa = require('./Google_app_store_badge_fr-ca.svg');
const hr = require('./Google_app_store_badge_hr.svg');
const hu = require('./Google_app_store_badge_hu.svg');
const it = require('./Google_app_store_badge_it.svg');
const ja = require('./Google_app_store_badge_ja.svg');
const ko = require('./Google_app_store_badge_ko.svg');
const lt = require('./Google_app_store_badge_lt.svg');
const lv = require('./Google_app_store_badge_lv.svg');
const no = require('./Google_app_store_badge_no.svg');
const nl = require('./Google_app_store_badge_nl.svg');
const pl = require('./Google_app_store_badge_pl.svg');
const ptBr = require('./Google_app_store_badge_pt-br.svg');
const pt = require('./Google_app_store_badge_pt.svg');
const ro = require('./Google_app_store_badge_ro.svg');
const ru = require('./Google_app_store_badge_ru.svg');
const sk = require('./Google_app_store_badge_sk.svg');
const sl = require('./Google_app_store_badge_sl.svg');
const sv = require('./Google_app_store_badge_sv.svg');
const tr = require('./Google_app_store_badge_tr.svg');
const zhCn = require('./Google_app_store_badge_zh-cn.svg');
const zhHk = require('./Google_app_store_badge_zh-hk.svg');
const zhTw = require('./Google_app_store_badge_zh-tw.svg');

const GoogleAppStoreImages = {
  bg,
  cs,
  da,
  de,
  el,
  en,
  es,
  et,
  fi,
  fr,
  frCa,
  hr,
  hu,
  it,
  ja,
  ko,
  lt,
  lv,
  no,
  nl,
  pl,
  ptBr,
  pt,
  ro,
  ru,
  sk,
  sl,
  sv,
  tr,
  zhCn,
  zhHk,
  zhTw
};

export default GoogleAppStoreImages;
