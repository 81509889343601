import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import {
  Background,
  ButtonContainer,
  Container,
  Description,
  Ellipse,
  EllipseContainer,
  GetSmartAppButton,
  Title
} from './styles';
import { HP_DEVICES_SETUP_APP } from '../../constants/links';
import { publishEvent, InstallHpSmartHyperlink } from '../../utils/analytics';

const AppStoresButton = () => {
  const { t } = useI18n();
  return (
    <ButtonContainer>
      <a
        data-testid="banner-app-stores-link"
        href={HP_DEVICES_SETUP_APP}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => publishEvent(InstallHpSmartHyperlink)}
      >
        <GetSmartAppButton>{t('appStores.buttonText')}</GetSmartAppButton>
      </a>
    </ButtonContainer>
  );
};

export const Banner = ({ showAppStores }) => {
  const { t } = useI18n();
  return (
    <Background data-testid="smart-advance-banner">
      <Container>
        <EllipseContainer data-testid="banner-ellipse">
          <Ellipse>{t('topBanner.badge')}</Ellipse>
        </EllipseContainer>
        <Title data-testid="banner-title">{t('topBanner.header')}</Title>
        <Description data-testid="banner-description">
          {t('topBanner.subHeader')}
        </Description>
        {showAppStores && <AppStoresButton />}
      </Container>
    </Background>
  );
};

Banner.propTypes = {
  showAppStores: PropTypes.bool.isRequired
};
