import styled from 'styled-components';

export const Header = styled.div`
  line-height: 28px;
  padding-bottom: 15px;
  margin-bottom: 13px;
  border-bottom: 1px solid #ebebeb;
  border-spacing: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > a {
    > img {
      box-sizing: border-box;
      height: 55px;
      width: auto;
      padding-right: 10px;
      padding-bottom: 10px;
    }
  }
`;

export const Description = styled.div`
  padding-bottom: 15px;
`;
