import PropTypes from 'prop-types';
import React from 'react';
import {
  Card as StyledCard,
  CardLink,
  ContentWrapper,
  Header,
  Title
} from './styles';

export const Card = (props) => {
  const { content, link, title } = props;
  const dataTestId = props['data-testid'];

  return (
    <StyledCard
      data-testid={dataTestId}
      content={
        <React.Fragment>
          <Header>
            <Title data-testid={`${dataTestId}-title`}>{title}</Title>
            {link && <CardLink href={link.href}>{link.text}</CardLink>}
          </Header>
          <ContentWrapper>{content}</ContentWrapper>
        </React.Fragment>
      }
    />
  );
};

Card.defaultProps = {
  link: null
};

Card.propTypes = {
  'data-testid': PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  }),
  title: PropTypes.string.isRequired
};
