export const ENTITLEMENTS = {
  SMART_ADVANCE: 'ws-hp.com/smart-advance',
  POTG: 'ws-hp.com/potg',
  SMART_DASHBOARD: 'ws-hp.com/smartdashboard'
};

export const ENTITLEMENT_TYPES = {
  BENEFIT: 'BENEFIT'
};

export const ENTITLEMENT_STATES = {
  ENABLED: 'ENABLED',
  PENDING: 'PENDING'
};

export const SMART_ADVANCE_SOLUTIONS = [
  {
    name: 'printAnywhere',
    entitlement: ENTITLEMENTS.POTG
  },
  {
    name: 'smartDashboard',
    entitlement: ENTITLEMENTS.SMART_DASHBOARD,
    featureFlag: 'smartAdvMobileProductivity'
  }
];
