const bannerNotebooks = require('./banner-notebooks.png');
const bannerBgModal = require('./banner-bg.png');
const bannerSmartLogo = require('./banner-smart-logo.png');
const iconAutoHeal = require('./icon-auto-heal.svg');
const iconBookScanning = require('./icon-book-scanning.svg');
const iconFlattenPages = require('./icon-flatten-pages.svg');
const iconIDCardScanning = require('./icon-id-card-scanning.svg');
const iconMultiItemRecognition = require('./icon-multi-item-recognition.svg');
const iconPrintAnywhere = require('./print-anywhere.svg');
const iconPrinter = require('./icon-printer.svg');
const iconSaveAsTextFiles = require('./icon-save-as-text-files.svg');
const iconSmartDashboard = require('./smart-dashboard.svg');
const iconSmartFileNaming = require('./icon-smart-file-naming.svg');
const iconTextExtract = require('./icon-text-extract.svg');

const Images = {
  bannerNotebooks,
  bannerBgModal,
  bannerSmartLogo,
  iconAutoHeal,
  iconBookScanning,
  iconFlattenPages,
  iconIDCardScanning,
  iconMultiItemRecognition,
  iconPrintAnywhere,
  iconPrinter,
  iconSaveAsTextFiles,
  iconSmartDashboard,
  iconSmartFileNaming,
  iconTextExtract
};

export default Images;
