import { HP_DEVICES_SETUP_APP } from '../constants/links';

// There are two different versions of the event data, the simpleUi version and the eventing version.
// These two constants make this verbally clear, to avoid future versioning problems.
const SIMPLE_UI_VERSION = '1.5.0';
const EVENTING_EVENT_VERSION = '1.4.0';

const EVENT_DETAIL_DEFAULT_VALUES = {
  activity: 'SolutionsMgmt-v01',
  version: SIMPLE_UI_VERSION,
  screenPath: '/ReactConsumerSmartAdvance/',
  screenName: 'SmartAdvance'
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked'
};

const CONTROL_NAMES = {
  INSTALL_HP_SMART: 'InstallHpSmart',
  APP_STORE: 'AppStore',
  GOOGLE_PLAY: 'GooglePlay',
  MICROSOFT: 'Microsoft',
  MAC_APP_STORE: 'MacAppStore',
  LEARN_MORE_HELP: 'LearnMoreHelp'
};

const CONTROL_DETAILS = {
  HP_DEVICES_SETUP_APP,
  HELP_HP_SMART_ADVANCE: '/help/hp-smart-advance'
};

// Event calls

export const publishEvent = (event, options) => {
  const publishCdmEvents = window.Shell?.v1?.analytics?.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const events = Array.isArray(event) ? event : [event];

  const cdmEvents = events.map((item) => ({
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: item,
    version: EVENTING_EVENT_VERSION
  }));

  publishCdmEvents(cdmEvents, options);
};

// ======= Events values =======

// Home

export const HomeScreenDisplayed = {
  ...EVENT_DETAIL_DEFAULT_VALUES,
  action: ACTIONS.SCREEN_DISPLAYED
};

// Buttons

export const InstallHpSmartHyperlink = {
  ...EVENT_DETAIL_DEFAULT_VALUES,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlDetail: CONTROL_DETAILS.HP_DEVICES_SETUP_APP,
  controlName: CONTROL_NAMES.INSTALL_HP_SMART
};

export const AppStoreHyperlink = {
  ...EVENT_DETAIL_DEFAULT_VALUES,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlDetail: CONTROL_DETAILS.HP_DEVICES_SETUP_APP,
  controlName: CONTROL_NAMES.APP_STORE
};

export const GooglePlayHyperlink = {
  ...EVENT_DETAIL_DEFAULT_VALUES,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlDetail: CONTROL_DETAILS.HP_DEVICES_SETUP_APP,
  controlName: CONTROL_NAMES.GOOGLE_PLAY
};

export const MicrosoftHyperlink = {
  ...EVENT_DETAIL_DEFAULT_VALUES,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlDetail: CONTROL_DETAILS.HP_DEVICES_SETUP_APP,
  controlName: CONTROL_NAMES.MICROSOFT
};

export const MacAppStoreHyperlink = {
  ...EVENT_DETAIL_DEFAULT_VALUES,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlDetail: CONTROL_DETAILS.HP_DEVICES_SETUP_APP,
  controlName: CONTROL_NAMES.MAC_APP_STORE
};

export const LearnMoreHelpHyperlink = {
  ...EVENT_DETAIL_DEFAULT_VALUES,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlDetail: CONTROL_DETAILS.HELP_HP_SMART_ADVANCE,
  controlName: CONTROL_NAMES.LEARN_MORE_HELP
};
