export const getFeatureFlag = async ({
  key = '',
  defaultValue = false
} = {}) => {
  if (!key) return defaultValue;

  try {
    const client = await window.Shell.v1.featureFlags.getClient('ucde-portal');
    const featureFlagValue = await client.getFeatureFlag({
      key,
      defaultValue
    });

    return featureFlagValue;
  } catch (error) {
    return defaultValue;
  }
};
