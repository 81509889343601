import React from 'react';
import PropTypes from 'prop-types';
import Animations from '../../animations';
import { Card } from '../Card';
import { CardItem } from '../CardItem';
import { Carousel } from '../Carousel';
import { CarouselItem } from '../CarouselItem';
import Images from '../../assets/images';
import { useI18n } from '@jarvis/react-portal-addons';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export const MobileProductivityCard = ({ solutions }) => {
  const { t } = useI18n();
  const smartAdvMobileProductivity = useFeatureFlags(
    'smartAdvMobileProductivity'
  );

  return (
    <Card
      data-testid="mobile-productivity-card"
      title={t('mobileProductivityCard.header')}
      content={
        <React.Fragment>
          {solutions.printAnywhere && (
            <CardItem
              image={{ url: Images.iconPrintAnywhere.toString() }}
              title={t('mobileProductivityCard.feature2.header')}
              description={t('mobileProductivityCard.feature2.description')}
            />
          )}
          {solutions.smartDashboard && smartAdvMobileProductivity && (
            <CardItem
              image={{ url: Images.iconSmartDashboard.toString() }}
              title={t('mobileProductivityCard.feature3.header')}
              description={t('mobileProductivityCard.feature3.description')}
            />
          )}
        </React.Fragment>
      }
    />
  );
};

MobileProductivityCard.propTypes = {
  solutions: PropTypes.objectOf(PropTypes.shape).isRequired
};

export const MobileProductivityCarousel = ({ solutions }) => {
  const { t } = useI18n();
  const smartAdvMobileProductivity = useFeatureFlags(
    'smartAdvMobileProductivity'
  );

  return (
    <>
      <Carousel
        sliderTopAndBottomMargin="10px"
        title={t('mobileProductivityCard.header')}
      >
        {solutions.printAnywhere && (
          <CarouselItem
            animation={{
              animationData: Animations.printAnywhere,
              containerId: 'printer-anywhere-corousel-item-container'
            }}
            title={t('mobileProductivityCard.feature2.header')}
            description={t('mobileProductivityCard.feature2.description')}
          />
        )}
        {solutions.smartDashboard && smartAdvMobileProductivity && (
          <CarouselItem
            animation={{
              animationData: Animations.smartDashboard,
              containerId: 'smart-dashboard-corousel-item-container'
            }}
            title={t('mobileProductivityCard.feature3.header')}
            description={t('mobileProductivityCard.feature3.description')}
          />
        )}
      </Carousel>
    </>
  );
};

MobileProductivityCarousel.propTypes = {
  solutions: PropTypes.objectOf(PropTypes.shape).isRequired
};
