import React, { useEffect } from 'react';
import { useSubscriptionInfoApiCall } from './hooks/useSubscriptionInfoApiCall';
import { useRootContext } from '@jarvis/react-portal-addons';
import { getTenantId } from './utils/auth';
import { publishEvent, HomeScreenDisplayed } from './utils/analytics';
import HpSmartAdvance from './pages/HpSmartAdvance';

const App = () => {
  const { authProvider, stack, orgSelector } = useRootContext();

  const subscriptionInfo = useSubscriptionInfoApiCall({
    authProvider,
    stack
  });

  useEffect(() => {
    const forceFetchSubscriptionInfo = async () => {
      const tenantId = await getTenantId(orgSelector, authProvider);

      subscriptionInfo.forceFetch({
        accountId: tenantId
      });
    };

    forceFetchSubscriptionInfo();
    // This useEffect should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProvider, orgSelector]);

  useEffect(() => {
    publishEvent(HomeScreenDisplayed);
  }, []);

  return <HpSmartAdvance subscriptionInfo={subscriptionInfo.data} />;
};

export default App;
