import styled from 'styled-components';
import { Link } from '@jarvis/react-portal-addons';

export const Container = styled.div``;

export const Title = styled.h6`
  color: #464d50;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding-bottom: 8px;
  font-weight: 700;
  font-size: 20px;

  @media (max-width: ${(props) => props.theme.screens.dmd}) {
    ${(props) => props.theme.host.isDesktopApp && 'margin-top: 0;'};
  }

  @media (max-width: ${(props) => props.theme.screens.md}) {
    margin-top: 0;
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    margin-top: 20px;
  }
`;

export const HelpLink = styled(Link)`
  font-weight: 700;
`;
