import React from 'react';
import Animations from '../../animations';
import { Card } from '../Card';
import { CardItem } from '../CardItem';
import Images from '../../assets/images';
import { Carousel } from '../Carousel';
import { CarouselItem } from '../CarouselItem';
import { useI18n } from '@jarvis/react-portal-addons';

export const ShortcutsCard = () => {
  const { t } = useI18n();
  return (
    <Card
      data-testid="shortcuts-card"
      title={t('shortcutsCard.header')}
      content={
        <React.Fragment>
          <CardItem
            image={{ url: Images.iconSaveAsTextFiles.toString() }}
            title={t('shortcutsCard.feature1.header')}
            description={t('shortcutsCard.feature1.description')}
          />
          <CardItem
            image={{ url: Images.iconSmartFileNaming.toString() }}
            title={t('shortcutsCard.feature2.header')}
            description={t('shortcutsCard.feature2.description')}
          />
        </React.Fragment>
      }
    />
  );
};

export const ShortcutsCarousel = () => {
  const { t } = useI18n();
  return (
    <React.Fragment>
      <Carousel
        sliderTopAndBottomMargin="10px"
        title={t('shortcutsCard.header')}
      >
        <CarouselItem
          animation={{
            animationData: Animations.saveAsTextFiles,
            containerId: 'shortcuts-save-as-text-files-corousel-item-container'
          }}
          title={t('shortcutsCard.feature1.header')}
          description={t('shortcutsCard.feature1.description')}
        />
        <CarouselItem
          animation={{
            animationData: Animations.smartFileNaming,
            containerId: 'shostcuts-smart-file-naming-corousel-item-container'
          }}
          title={t('shortcutsCard.feature2.header')}
          description={t('shortcutsCard.feature2.description')}
        />
      </Carousel>
    </React.Fragment>
  );
};
