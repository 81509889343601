import { jWebReady } from '@jarvis/jweb-core';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { Stack } from '@jarvis/web-stratus-client';

export const getJWebIsAvailable = async () =>
  jWebReady.then((jweb) => !!jweb?.isNative).catch(() => false);

export const isJWebNative = () => window.JWeb?.isNative;

const isNativeAndWithAuthPluginValid = () =>
  isJWebNative() && window.JWeb?.Plugins?.Auth;

const isJWebDesktopApp = () => !isMobile && isNativeAndWithAuthPluginValid();

const isJWebiOSApp = () => isIOS && isNativeAndWithAuthPluginValid();

const isJWebAndroidApp = () => isAndroid && isNativeAndWithAuthPluginValid();

export const getEnvironmentConfig = (stack) => ({
  isNative: isJWebNative(),
  isJWebDesktopApp: isJWebDesktopApp(),
  isJWebiOSApp: isJWebiOSApp(),
  isJWebAndroidApp: isJWebAndroidApp(),
  stack: Stack[stack]
});
