import React from 'react';
import PropTypes from 'prop-types';
import { Content, Description, Header } from './styles';
import { Card, Title } from '../Card/styles';
import {
  GoogleAppStoreBadge,
  MacAppStoreBadge,
  MicrosoftAppStoreBadge,
  iOSAppStoreBadge,
  useStoreBadges
} from '../../assets/images/app-store/appStoreBadges';
import { useI18n } from '@jarvis/react-portal-addons';
import { HP_DEVICES_SETUP_APP } from '../../constants/links';
import {
  publishEvent,
  AppStoreHyperlink,
  GooglePlayHyperlink,
  MicrosoftHyperlink,
  MacAppStoreHyperlink
} from '../../utils/analytics';

const AppStoreBadge = ({ testId, image, onClick }) => (
  <a
    data-testid={testId}
    href={HP_DEVICES_SETUP_APP}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
  >
    <img
      src={image.url}
      alt={image.alt}
    />
  </a>
);

AppStoreBadge.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  testId: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

AppStoreBadge.defaultProps = {
  testId: ''
};

export const AppStores = () => {
  const { t } = useI18n();
  const { getLocaleRedundant } = useStoreBadges();
  const localeRedundant = getLocaleRedundant();
  return (
    <Card
      data-testid="app-stores-card"
      content={
        <React.Fragment>
          <Header>
            <Title data-testid="app-stores-card-title">
              {t('appStores.cardHeader')}
            </Title>
          </Header>
          <Description
            data-testid="app-stores-card-description"
            className="caption"
          >
            {t('appStores.cardDescription')}
          </Description>
          <Content>
            <AppStoreBadge
              onClick={() => publishEvent(AppStoreHyperlink)}
              testId="app-store-badge-ios"
              image={{
                url: iOSAppStoreBadge[localeRedundant].toString(),
                alt: 'Download the Smart App on the App Store'
              }}
            />
            <AppStoreBadge
              onClick={() => publishEvent(GooglePlayHyperlink)}
              testId="app-store-badge-google"
              image={{
                url: GoogleAppStoreBadge[localeRedundant].toString(),
                alt: 'Download the Smart App on Google Play'
              }}
            />
            <AppStoreBadge
              onClick={() => publishEvent(MicrosoftHyperlink)}
              testId="app-store-badge-microsoft"
              image={{
                url: MicrosoftAppStoreBadge[localeRedundant].toString(),
                alt: 'Download the Smart App from Microsoft'
              }}
            />
            <AppStoreBadge
              onClick={() => publishEvent(MacAppStoreHyperlink)}
              testId="app-store-badge-mac"
              image={{
                url: MacAppStoreBadge[localeRedundant].toString(),
                alt: 'Download the Smart App on the Mac App Store'
              }}
            />
          </Content>
        </React.Fragment>
      }
    />
  );
};
