import NukaCarousel from 'nuka-carousel';
import styled from 'styled-components';

export const Carousel = styled(NukaCarousel)`
  .slider-control-bottomcenter {
    position: relative !important;
    display: flex;
    justify-content: center;
    margin: ${(props) =>
      props.sliderTopAndBottomMargin
        ? `${props.sliderTopAndBottomMargin} 0`
        : ''};

    > ul {
      top: 0 !important;
    }
  }

  .paging-item {
    button {
      fill: #dbdbdb !important;

      &:focus {
        outline: none !important;
      }

      .paging-dot {
        height: 8px;
        width: 8px;

        > circle {
          cx: 4px;
          cy: 4px;
          r: 4px;
        }
      }
    }

    &.active {
      button {
        fill: #027aae !important;
      }

      .paging-dot {
        height: 10px;
        width: 10px;

        > circle {
          cx: 5px;
          cy: 5px;
          r: 5px;
        }
      }
    }
  }
`;

export const Header = styled.div`
  margin: 5px 30px 0 39px;
`;

export const Title = styled.div`
  display: inline-block;
  font-size: 20px;

  @media (max-width: ${(props) => props.theme.screens.dsm}) {
    ${(props) => props.theme.host.isDesktopApp && 'color: #4E4E4E;'};
  }

  @media (max-width: ${(props) => props.theme.screens.sm}) {
    color: #4e4e4e;
  }
`;

export const HeaderLink = styled.div`
  color: #027aae;
  display: inline-block;
  text-align: right;
  width: 50%;
`;

export const ContentWrapper = styled.div`
  margin: 0 16px;
`;
