import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import {
  AnimationContainer,
  Content,
  Description,
  StyledMobileCarouselItem,
  Title
} from './styles';

export const CarouselItem = memo((props) => {
  const { animation, description, title } = props;

  useEffect(() => {
    if (animation) {
      lottie.loadAnimation({
        animationData: animation.animationData,
        container: document.getElementById(animation.containerId),
        renderer: 'svg',
        loop: true,
        autoplay: true
      });
    }
  }, [animation]);

  return (
    <StyledMobileCarouselItem data-testid="carousel-item-container">
      <AnimationContainer
        data-testid="carousel-item-animation"
        id={animation.containerId}
      />
      <Content>
        <Title
          data-testid="carousel-item-title"
          className="caption"
        >
          {title}
        </Title>
        <Description
          data-testid="carousel-item-description"
          className="caption"
        >
          {description}
        </Description>
      </Content>
    </StyledMobileCarouselItem>
  );
});

CarouselItem.displayName = 'CarouselItem';

CarouselItem.propTypes = {
  animation: PropTypes.shape({
    animationData: PropTypes.objectOf(PropTypes.shape).isRequired,
    containerId: PropTypes.string.isRequired
  }).isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
