import PropTypes from 'prop-types';
import React from 'react';
import {
  Carousel as StyledCarousel,
  ContentWrapper,
  Header,
  HeaderLink,
  Title
} from './styles';

export const Carousel = (props) => {
  const {
    children,
    hideControls,
    link,
    title,
    description,
    sliderTopAndBottomMargin
  } = props;

  return (
    <React.Fragment>
      <Header data-testid="carousel-header">
        <Title data-testid="carousel-header-title">{title}</Title>
        {link && (
          <HeaderLink
            data-testid="carousel-header-link"
            href={link.href}
          >
            {link.text}
          </HeaderLink>
        )}
      </Header>
      {description}
      <ContentWrapper data-testid="carousel-header-content">
        <StyledCarousel
          cellSpacing={18}
          dragging
          slideWidth="288px"
          speed={1000}
          swiping
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          withoutControls={hideControls}
          sliderTopAndBottomMargin={sliderTopAndBottomMargin}
        >
          {children}
        </StyledCarousel>
      </ContentWrapper>
    </React.Fragment>
  );
};

Carousel.defaultProps = {
  description: null,
  hideControls: false,
  link: null,
  sliderTopAndBottomMargin: ''
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.object,
  hideControls: PropTypes.bool,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  }),
  title: PropTypes.string.isRequired,
  sliderTopAndBottomMargin: PropTypes.string
};
